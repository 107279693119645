import React, { useEffect, useState } from 'react'
import { AppBar, Toolbar, Box, IconButton } from '@mui/material'
import { Link } from 'react-scroll'
import Sidebar from './SideBar'
import MenuIcon from '@mui/icons-material/Menu'

const linkStyle = {
  color: 'black',
  textDecoration: 'none',
  margin: '0 10px',
  cursor: 'pointer',
  fontWeight: '1000',
  fontFamily: 'Gothic',
  fontSize: '0.875rem',
  lineHeight: '1.75',
  transition: 'color 0.3s',
  padding: '6px 8px',
}

const activeStyle = {
  borderBottom: '2px solid #1d4ed8',
  color: '#1d4ed8',
}

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY)
  const [sidebarOpen, toggleSidebar] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY))
    return () => {
      window.removeEventListener('scroll', () => setY(window.scrollY))
    }
  }, [y])

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <AppBar position='fixed' style={{ backgroundColor: 'white' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box padding={0.5}>
            <Link to='aboutus' smooth={true}>
              <img src='/assets/HTTruckLogo.png' height={40} alt='Logo' />
            </Link>
          </Box>
          <Box display={{ xs: 'blcok', md: 'none' }}>
            <IconButton onClick={() => toggleSidebar(!sidebarOpen)}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Box display={{ xs: 'none', md: 'block' }}>
            <Link
              offset={-50}
              style={linkStyle}
              to='aboutus'
              smooth={true}
              activeStyle={activeStyle}
              spy={true}
            >
              ABOUT US
            </Link>
            <Link
              offset={-50}
              style={linkStyle}
              to='services'
              smooth={true}
              activeStyle={activeStyle}
              spy={true}
            >
              SERVICES
            </Link>
            <Link
              offset={-55}
              style={linkStyle}
              to='brands'
              smooth={true}
              activeStyle={activeStyle}
              spy={true}
            >
              BRANDS
            </Link>
            <Link
              offset={-20}
              style={linkStyle}
              to='contactus'
              smooth={true}
              activeStyle={activeStyle}
              spy={true}
            >
              CONTACT
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}
