import { Typography, Box, Divider, Link, IconButton } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

export default function Footer() {
  const socialLinks = [
    {
      href: 'https://www.facebook.com/htgroup1953',
      icon: <FacebookIcon fontSize='large' style={{ fill: 'white' }} />,
    },
    {
      href: 'https://www.linkedin.com/company/ht-group-sdn-bhd',
      icon: <LinkedInIcon fontSize='large' style={{ fill: 'white' }} />,
    },
  ]

  const address = [
    {
      address: 'Johor Bahru (HQ): No 90, Jalan Tampoi, 81200 Johor Bahru, Johor',
      tel: '+607-335 8989',
      fax: '+607-335 8600',
    },
    {
      address:
        'Masai: No.25, Jalan Cenderai 22, Kawasan Perindustrian Kota Puteri, 81750 Masai, Johor',
      tel: '+607-387 0958',
      fax: '+607-387 0598',
    },
    {
      address:
        'Gelang Patah: No.6, Jalan SILC 2/19, Kawasan Perindustrian SILC, 79200 Iskandar Puteri, Johor',
      tel: '+607-570 5990',
      fax: '',
    },
  ]

  return (
    <Box
      bgcolor='#222222'
      color='#ffff'
      px={3}
      py={5}
      display='flex'
      justifyContent='space-between'
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems='center'
      textAlign={{ xs: 'center', md: 'left' }}
    >
      <Box flexGrow={1} maxWidth={900}>
        <Box mb={1}>
          <Typography fontSize='1.25rem' fontFamily={'Gothic Bold'}>
            Contact
          </Typography>
        </Box>
        <Box mb={1}>
          <Divider style={{ background: 'white' }} />
        </Box>
        <Box mb={1}>
          <Typography fontSize='0.875rem' fontFamily={'Gothic Bold'}>
            HT Truck Service Sdn Bhd
          </Typography>
        </Box>
        {address.map((item, index) => (
          <Box key={index} pt={1} color='#ffff' fontSize='0.875rem'>
            <Typography fontSize='0.875rem'>{item.address}</Typography>
            <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }}>
              <Typography fontSize='0.875rem'>Tel: {item.tel}</Typography>
              <Box marginLeft={{ md: 2 }}>
                {item.fax && <Typography fontSize='0.875rem'>Fax: {item.fax}</Typography>}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box pt={1} pl={{ xs: 0, md: 5 }} minWidth='100px'>
        {socialLinks.map(({ href, icon }, index) => (
          <Link href={href} target='_blank' key={index}>
            <IconButton>{icon}</IconButton>
          </Link>
        ))}
      </Box>
    </Box>
  )
}
