import React from 'react'
import { Typography, Box } from '@mui/material'

export default function Brands() {
  const brandsArray = [
    '/assets/Brands/1.png',
    '/assets/Brands/2.png',
    '/assets/Brands/3.png',
    '/assets/Brands/4.png',
    '/assets/Brands/5.png',
    '/assets/Brands/6.png',
    '/assets/Brands/7.png',
    '/assets/Brands/8.png',
  ]

  return (
    <div id='brands'>
      <Box justifyContent='center' textAlign='center' mt={15}>
        <Typography
          sx={{
            fontSize: { xs: 30, md: 50 },
            fontFamily: 'Corsica LX Bold',
          }}
        >
          BRANDS WE CARRY
        </Typography>
        <Box
          mt={5}
          display='grid'
          gridTemplateColumns={{
            xs: '1fr',
            sm: 'repeat(auto-fit, minmax(300px,1fr))',
            md: 'repeat(auto-fit, minmax(300px,1fr))',
          }}
          p={3}
        >
          {brandsArray?.map((each, idx) => (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{ mb: { xs: 1, md: 3 } }}
              key={idx}
            >
              <img src={each} height={200} />
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  )
}
