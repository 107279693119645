import React from 'react'
import { Box, Typography } from '@mui/material'

const divStyle = {
  backgroundImage: 'url("/assets/AboutUsBackground.png")',
  backgroundSize: 'cover',
  height: '800px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  overflowX: 'hidden',
  overflowY: 'hidden',
}

export default function AboutUs() {
  return (
    <div id='aboutus' style={divStyle}>
      <Box
        height='100%'
        width='100%'
        flexDirection='column'
        pl={{ xs: 10, md: 20, lg: 20 }}
        pt={{ xs: 25, sm: 30, md: 30 }}
      >
        <Box maxWidth={{ xs: 210, sm: 320, md: 400, lg: 500 }} textAlign='left'>
          <Typography
            sx={{
              fontSize: { xs: '23px', sm: '35px', lg: '40px' },
              color: '#fff',
              fontFamily: 'Corsica LX Bold',
              lineHeight: 1,
            }}
          >
            ABOUT US
          </Typography>
          <hr
            style={{
              width: '75%',
              height: '4px',
              backgroundColor: '#fff',
              border: 'none',
              margin: '8px 0',
            }}
          />
          <br />
          <Typography
            sx={{
              fontSize: { sm: '13px', md: '18px', lg: '20px' },
              color: '#fff',
            }}
          >
            Established in 1953 as a small car tyre shop, HT Truck Service was form with the aim to
            provide a full range of tyre services to the transport industry. HT ensures excellent
            accessibility and top-notch products and services our customers.
          </Typography>
        </Box>
      </Box>
    </div>
  )
}
