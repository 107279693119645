import React from 'react'
import { Typography, Box } from '@mui/material'

export default function Services() {
  const servicesArray = [
    '/assets/Services/1.png',
    '/assets/Services/2.png',
    '/assets/Services/3.png',
    '/assets/Services/4.png',
    '/assets/Services/5.png',
  ]
  return (
    <div id='services'>
      <Box display='flex' justifyContent='center' textAlign='center' mt={5}>
        <Typography
          sx={{
            fontSize: { xs: 30, md: 50 },
            fontFamily: 'Corsica LX Bold',
          }}
        >
          SERVICES WE OFFER
        </Typography>
      </Box>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box display='flex' mt={5} flexDirection={{ xs: 'column', sm: 'row' }}>
          {servicesArray?.map((each) => (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              p={1}
              key={each}
              textAlign='center'
            >
              <img src={each} height='auto' width='100%' />
            </Box>
          ))}
        </Box>
      </div>
    </div>
  )
}
